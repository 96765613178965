import { v4 as uuidv4 } from "uuid";

import { FleetFormType, FleetPermissions } from "../components/modals/EditFleetModal/schema";
import { ApiResponse, handleResponse } from "../store/utils";
import { AppFleet, MainAppService } from "../typegens";

export const createFleet = async (form: FleetFormType): Promise<ApiResponse<AppFleet>> => {
  const id = uuidv4();
  const result = await handleResponse(() =>
    MainAppService.saveOrUpdateFleet({
      id,
      requestBody: {
        id: id,
        cash: form.cash,
        name: form.fleetName,
        customerId: form.customer.value,
        customerName: form.customer.label,
        permissions: [form.permissions.value as FleetPermissions],
        businessUnitId: form.businessUnitId,
        adminId: form.adminId,
        adminReferenceId: form.adminReferenceId,
        allow: form.allow,
        assignedPortIds: form.assignedPortIds,
        invoiceAccounts: form.invoiceAccounts,
        srn: form.srn,
        stocktakeCurrency: form.stocktakeCurrency?.value,
      },
    })
  );

  return result;
};
