import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { CircledImage, Paragraph, containerPadding, portPalette, string2color } from "@web/ui";

import BusinessPelican from "../../../icons/BusinessPelican.svg";
import VesselIcon from "../../../icons/VesselIcon.svg";
import { Vessel } from "../../../models/Vessel";

type GatherProps = {
  vessel: Pick<Vessel, "name" | "id">;
  onBackToOverview: () => void;
  isPunchOutIntegration?: boolean;
};

export const GatherShipBar: React.FC<GatherProps> = ({
  vessel,
  onBackToOverview,
  isPunchOutIntegration,
}) => {
  const { t } = useTranslation();
  const { name, id } = vessel;
  const topBarColor = string2color(id, portPalette);

  return (
    // TOPBAR
    <div className="bg-neutral_0 border-b border-b-neutral_200 w-full z-30">
      <div className={`w-full h-1 ${topBarColor} fixed z-10`} />
      <div
        className={`${containerPadding} relative flex justify-between content-center items-center bg-neutral_0`}
        style={{ height: "68px" }}
      >
        <Menu as="div" className="absolute text-left">
          <div>
            <Menu.Button className="inline-flex items-center justify-center w-full pt-3 pl-2 bg-neutral_0 focus:outline-none focus-visible:ring-offset-neutral_200 focus-visible:ring-primaryDefault">
              <img src={BusinessPelican} alt="pelican icon" />
              {!isPunchOutIntegration && (
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 text-text-whiteDisabled"
                  aria-hidden="true"
                />
              )}
            </Menu.Button>
          </div>
          {!isPunchOutIntegration && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="z-30 origin-top-right absolute right-50 mt-2 w-56 rounded-md shadow-lg bg-neutral_0 ring-1 ring-black ring-opacity-5 focus:outline-none whitespace-nowrap">
                <div className="py-1">
                  <Menu.Item>
                    <button onClick={onBackToOverview} className="flex flex-col px-4 py-3">
                      <p className="text-textIcon-blackSecondary">
                        {t("common.layouts.gatherNav.backTo")}
                      </p>
                      <p className="text-primaryDefault">
                        {t("common.layouts.gatherNav.orderOverview")}
                      </p>
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          )}
        </Menu>
        <div className="flex m-auto items-center pt-px">
          <CircledImage Icon={VesselIcon} size={5} hashKey={id} />
          <Paragraph size="200" className="pl-1">
            {name}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
