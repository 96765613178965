import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { BasketEntry } from "@web/common/models/basket";
import { OrderRequisition } from "@web/common/network/model";
import { Money } from "@web/models";

import { useApproveQuotationMutation } from "../../../hooks/useApproveQuotationMutation";
import { toQuotationApproveItem, toQuotationApproveItemList } from "./utils";

interface Props {
  lineItems: BasketEntry[];
  quotationId: string;
  orderRequisition: OrderRequisition;
  totalPrice: Money;
}

export const QuotationApproval = ({
  lineItems,
  quotationId,
  orderRequisition,
  totalPrice,
}: Props) => {
  const { mutate, isPending } = useApproveQuotationMutation();
  const items = toQuotationApproveItemList(lineItems);
  const hasEditedItems = Boolean(items.length);

  const submitOrderHandler = async (orderSummary: OrderInfoFormValues) => {
    // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
    // In reality this will never happen thanks to the validation set up in OrderInfo component.
    if (!orderSummary.agentInformation) {
      throw Error("Agent info not defined");
    }
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date not defined");
    }

    mutate({
      id: quotationId,
      requestBody: {
        agent: orderSummary.agentInformation,
        consolidated: false,
        deliveryDate: orderSummary.deliveryDate,
        orderNotes: orderSummary.orderNotes,
        storageLabel: orderSummary.storageLabel,
        customerOrderId: orderSummary.customerOrderId,
        items: hasEditedItems
          ? items
          : orderRequisition.items.map((item) => toQuotationApproveItem(item)),
      },
    });
  };

  return (
    <FormLayout>
      <OrderInfo
        creationMode={"QUOTATION_APPROVAL"}
        grandTotal={hasEditedItems ? totalPrice : orderRequisition.totalGrossAmount}
        loading={isPending}
        nrLineItems={hasEditedItems ? items.length : orderRequisition.items.length}
        nrRfqItems={0}
        nrExtraItems={0}
        orderRequisition={orderRequisition}
        port={orderRequisition.port}
        submitForm={submitOrderHandler}
        vessel={orderRequisition.vessel}
        withOrderComment
        supplier={undefined}
        defaultAgentInformation={orderRequisition.agentInformation}
      />
    </FormLayout>
  );
};
