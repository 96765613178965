import { BasketEntry } from "@web/common";
import { DEFAULT_CURRENCY_CODE } from "@web/common/config/constants";
import {
  ChangeType,
  OrderItem,
  OrderRequisition,
  ProductChange,
  ProductItem,
} from "@web/common/network/model";
import { getUnchangedOrderItems } from "@web/common/utils";

import { AppOrderRequisition, AppProductChange, AppPurchaseOrderItem } from "../typegens";

export const toOrderItem = (item: Required<AppPurchaseOrderItem>): OrderItem => ({
  ...item,
  totalAmount: {
    ...item.singleGrossPrice,
    amount: item.singleGrossPrice.amount * item.entityQuantity,
  },
});

export const toProductChange = (item: AppProductChange): ProductChange => ({
  ...item,
  totalAmount: {
    ...item.singleGrossPrice,
    amount: item.singleGrossPrice.amount * item.entityQuantity,
  },
  ...(item.oldSingleGrossPrice &&
    item.oldEntityQuantity && {
      oldTotalAmount: {
        ...item.oldSingleGrossPrice,
        amount: item.oldSingleGrossPrice.amount * item.oldEntityQuantity,
      },
    }),
});

export const convertOrderCatalogItemToProductItem = (catalogItem: OrderItem): ProductItem => ({
  entityQuantity: catalogItem.entityQuantity,
  measurementUnit: catalogItem.measurementUnit,
  name: catalogItem.name,
  singleGrossPrice: catalogItem.singleGrossPrice,
  totalAmount: catalogItem.totalAmount,
  impaCode: catalogItem.impaCode,
  skuDetails: catalogItem.skuDetails,
  lineNumber: catalogItem.lineNumber,
});

const extractSupplierIdFromBasketEntries = (basketEntries: Record<string, BasketEntry>) => {
  return Object.values(basketEntries)[0]?.sku.supplierInformation.supplierId;
};

const convertBasketEntriesToCreationItems = (basketEntries: Record<string, BasketEntry>) => {
  return Object.values(basketEntries).map((entry) => {
    return {
      variantId: entry.sku.id,
      quantity: entry.quantity * entry.sku.salesEntityQuantity,
    };
  });
};

export const LocalOrderReqService = {
  convertBasketEntriesToCreationItems,
  extractSupplierIdFromBasketEntries,
  toOrderRequisition: (order: AppOrderRequisition): OrderRequisition => {
    const items = order.items.map((o) => toOrderItem(o as Required<AppPurchaseOrderItem>));
    const changes = order.changes.map((change) => toProductChange(change));
    const changesOrder: ChangeType[] = ["ADDED", "REMOVED", "UPDATED", "REPLACED"];

    return {
      ...order,
      canCancel:
        (order.status === "SUPPLIER_CONFIRMED" && !order.createdByExternalSystem) ||
        (order.status === "SUPPLIER_PENDING" && !order.createdByExternalSystem) ||
        (order.status === "SUPPLIER_ACKNOWLEDGED" && !order.createdByExternalSystem),
      canClose:
        (order.status === "SUPPLIER_CONFIRMED" ||
          order.status === "CANCELLED" ||
          order.status === "SUPPLIER_DELIVERED") &&
        order.type === "ORDER" &&
        !order.closed,
      canDownloadMTML:
        order.status === "SUPPLIER_CONFIRMED" || order.status === "SUPPLIER_DELIVERED",
      canDownloadExcel: order.type !== "QUOTATION",
      canDeleteDraft: order.type === "DRAFT",
      changes,
      items,
      changeTypeList: Array.from(new Set(changes.map((change) => change.changeType))).sort(
        (a, b) => changesOrder.indexOf(a) - changesOrder.indexOf(b)
      ),
      unchangedItems: getUnchangedOrderItems(items, changes),
      deliveryDate: order.deliveryDate ?? "",
      createdAt: new Date(order.createdAt),
      updatedAt: new Date(order.updatedAt),
      orderDate: order.orderDate ? new Date(order.orderDate) : undefined,
      requestDate: order.requestDate ? new Date(order.requestDate) : undefined,
      quotationDate: order.quotationDate ? new Date(order.quotationDate) : undefined,
      isRfqRequisition:
        order.type === "REQUISITION" && order.rfqItems && order.rfqItems.length > 0 ? true : false,
      totalGrossAmount: order.totalGrossAmount || {
        amount: 0,
        currencyCode: DEFAULT_CURRENCY_CODE,
      },
    };
  },
};
