import { z } from "zod";

export enum FleetPermissions {
  CREATE_AUTO_APPROVED_REQUISITION = "CREATE_AUTO_APPROVED_REQUISITION",
  CREATE_REQUISITION = "CREATE_REQUISITION",
  CREATE_ORDER = "CREATE_ORDER",
}

export const StocktakeCurrencyCodes = ["USD", "EUR"] as const;
export type StocktakeCurrencyCodesType = typeof StocktakeCurrencyCodes[number];

const InvoiceAccountSchema = z.object({
  id: z.string(),
  taxNumber: z.string(),
  supplierInvoiceId: z.string(),
  registerOwner: z.string(),
  billingAddressLine1: z.string(),
  billingAddressLine2: z.string().optional(),
  billingAddressLine3: z.string().optional(),
  country: z.string(),
  approvalRequired: z.boolean(),
});

export const FleetSchema = z
  .object({
    fleetName: z.string().min(1, { message: "Fleet name is required" }),
    customer: z.object({ label: z.string(), value: z.string() }),
    permissions: z.object({ label: z.string(), value: z.string() }),
    businessUnitId: z.string(),
    adminId: z.string(),
    adminReferenceId: z.string(),
    assignedPortIds: z.array(z.string()),
    invoiceAccounts: z.array(InvoiceAccountSchema),
    srn: z.string().optional(),
    cash: z.boolean().optional(),
    allow: z
      .object({
        createRfq: z.boolean(),
        fmsIntegration: z.boolean(),
        editable: z.boolean(),
        reorder: z.boolean(),
        offline: z.boolean(),
        offlineDraft: z.boolean(),
        punchOutIntegration: z.boolean(),
        customerOrderIdOptional: z.boolean(),
        foIntegration: z.boolean(),
        stocktaking: z.boolean(),
        manualNetworkState: z.boolean(),
        sortPurchaseOrderLines: z.boolean(),
        orderExtraItems: z.boolean(),
      })
      .required(),
    stocktakeCurrency: z
      .object({ label: z.string(), value: z.enum(StocktakeCurrencyCodes) })
      .optional(),
  })
  .refine((data) => !(data.allow.stocktaking && !data.stocktakeCurrency?.value), {
    message: "Stocktake Currency is required when Stocktaking is enabled",
  })
  .refine((data) => !(data.allow.orderExtraItems && !data.allow.offlineDraft), {
    message: "Offline Drafts has to be enabled to enable Order Extra Items",
  })
  .refine((data) => !(data.allow.orderExtraItems && data.allow.punchOutIntegration), {
    message: "PunchOut Integration and Order Extra Items cannot both be enabled",
  })
  .refine((data) => !(data.allow.orderExtraItems && data.allow.createRfq), {
    message: "Create RfQ and Order Extra Items cannot both be enabled",
  })
  .refine((data) => !(data.allow.createRfq && data.allow.punchOutIntegration), {
    message: "PunchOut Integration and Create RfQ cannot both be enabled",
  });

export type FleetFormType = z.infer<typeof FleetSchema>;
